import React, { useState } from "react"
import { Link } from "gatsby"

import styled from "styled-components"

import BlogPost from "../cards/blogpost"
import BigBlogPost from "../cards/bigBlogpost"

import InfiniteScroll from "react-infinite-scroll-component"

const BlogList = ({ data }) => {
  const [posts, setPosts] = useState(5)

  function fetchMore() {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      setPosts(posts + 6)
    }, 500)
  }

  function hasMore() {
    if (posts < data.length) {
      return true
    } else {
      return false
    }
  }

  return (
    <StyledBlog>
      <InfiniteScroll dataLength={posts} next={fetchMore} hasMore={hasMore}>
        {data.slice(0, posts).map((data, i) =>
          i === 0 ? (
            <Link to={data.node.frontmatter.path}>
              <BigBlogPost data={data.node.frontmatter} key={"blogpost" + i} />
            </Link>
          ) : (
            <Link to={data.node.frontmatter.path}>
              <BlogPost data={data.node.frontmatter} key={"blogpost" + i} />
            </Link>
          )
        )}
      </InfiniteScroll>
    </StyledBlog>
  )
}

export default BlogList

const StyledBlog = styled.div`
  .infinite-scroll-component {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4vw;
    grid-row-gap: 6vw;
    width: 100%;
    padding: 8vw 7.5%;
    h1 {
      font-size: 26px;
      letter-spacing: 0.1em;
    }
    > :nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    @media only screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 520px) {
      grid-template-columns: 1fr;
      > :nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
  }
`

import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const BigBlogPost = ({ data }) => {
  
  return(
    <StyledPost>
      <div className="postImg">
        <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }}/>
      </div>
      <div className="content">
        <p className={font.rSB + " data"}>{data.data}</p>
        <p className={font.rSB + " title"}>{data.title}</p>
      </div>
    </StyledPost>
  )
}

export default BigBlogPost

const StyledPost = styled.div`
  position: relative;
  height: 36vw;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .postImg{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .content{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 33%;
    overflow: hidden;
    padding: calc(18px + 12 * (100vw - 1200px) / 720) calc(28px + 12 * (100vw - 1200px) / 720);
    @media only screen and (min-width: 1920px){
      padding: 30px 40px;
    }
    background-color: rgba(35, 31, 32, 0.8);
  }
  .data{
    font-size: calc(14px + 10 * (100vw - 901px) / 1019);
    margin-bottom: 15px;
    @media only screen and (min-width: 1920px){
      font-size: 24px;
    }
    @media only screen and (max-width: 900px){
      font-size: calc(12px + 2 * (100vw - 521px) / 379);
      margin-bottom: calc(3px + 12 * (100vw - 521px) / 379);
    }
    color: #b7a99a;
  }
  .title{
    font-size: calc(18px + 14 * (100vw - 901px) / 1019);
    @media only screen and (min-width: 1920px){
      font-size: 32px;
    }
    @media only screen and (max-width: 900px){
      font-size: calc(13px + 5 * (100vw - 521px) / 379);
    }
    line-height: 1.2em;
    height: 2.3em;
    letter-spacing: 0.1em;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 520px){
    height: 310px;
    .content{
      padding: 10px 15px;
    }
    .data{
      font-size: 14px;
      margin-bottom: 5%;
    }
    .title{
      font-size: calc(13px + 2 * (100vw - 320px) / 200);
    }
  }
`
import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import BlogList from "../components/list/bloglist"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const NewsArticle = ({ data }) => (
  <Layout>
    <SEO title="Blogue | Carreira Dental Clinic" />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <BlogList data={data.allMarkdownRemark.edges} />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <BlogList data={data.allMarkdownRemark.edges} />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default NewsArticle

export const Json = graphql`
  query blog {
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            image {
              childImageSharp {
                fluid(quality: 70, maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
            date(formatString: "DD-MM-YYYY")
            data
            path
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
